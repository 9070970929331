@font-face {
	font-family: GothamPro;
	src: url(GothamPro.ttf);
}
* {
	box-sizing: border-box;
	font-family: GothamPro;
	/* убираем выделение текста */
	-moz-user-select: -moz-none;
	-o-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	user-select: none;
	outline: none;
}

html, body, div, canvas {
	margin: 0;
	padding: 0;
	border: 0;
}
body::before {
    content: '';
    background: url('./images/bg.jpg')no-repeat;
	background-size: cover;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
}
.max {
	max-width: 500px;
	margin: 0 auto;
	overflow: hidden;
}
.load {
	width: 100%;
	height: 100vh;
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: center;
	color: #fff;
}
.intro {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
}
.icon {
	background: url('./images/icon.png')no-repeat center center;
	margin: 0 auto 10px;
	height: 100px;
	width: 100px;
}
.description {
	margin: 0 auto;
	color: #fff;
	text-align: center;
	width: 80%;
	font-size: 16px;
	line-height: 16px;
	font-weight: bold;
	padding-bottom: 5px;
}
.let-subscribe {
	margin: 0 auto;
	width: 80%;
	text-align: center;
	padding-bottom: 10px;
	font-size: 14px;
	line-height: 14px;
	color: #fff;
}
.subscribe {
	margin: 0 auto;
	width: 70%;
	height: 50px;
	background: #075DCA;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 25px;
	color: #fff;
	font-weight: bold;
}
.continue {
	margin: 0 auto;
	width: 70%;
	height: 50px;
	color: #728FBB;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: underline;
}
.link {
	text-decoration: none;
}
a.link {
	outline: none;
}
a.link:focus {
	outline: none;
}
iframe > html > body > #config {
	display: none;
}
iframe {
	margin: 0 !important;
}
.player {
	border-radius: 12px;
	margin: 0px 10px 18px;
	overflow: hidden;
	padding: 0 !important;
	background: #000000 url('./images/load_cam.png')no-repeat center center;
	
}
.station-on-map {
	border-radius: 12px;
	overflow: hidden;
	margin: 10px 10px 30px;
	background: #101E34 url('./images/loading.png')no-repeat center center;
}
.build-station {
	border-radius: 12px;
	overflow: hidden;
	margin: 10px 10px 30px;
}
h1.label {
    margin: 24px 10px;
    padding: 0;
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
}
.label {
	color: #fff;
	font-weight: bold;
	font-size: 17px;
	margin-left: 10px;
}
.header {
	position: fixed;
	height: 60px;
	height: calc(60px + constant(safe-area-inset-top));
	height: calc(60px + env(safe-area-inset-top));
	width: 100%;
	background: url('./images/bg.jpg')no-repeat;
	background-attachment: fixed;
	background-size: cover;
	top: 0;
	z-index: 10000;
}
.header-space {
	height: 60px;
	height: calc(60px + constant(safe-area-inset-top));
	height: calc(60px + env(safe-area-inset-top));
	width: 100%;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
}
.buttons {
	display: flex;
	justify-content: space-between;
	box-sizing: border-box;
	margin: 0 5px;
}
.button-cam {
	display: flex;
	height: 51px;
	width: 50%;
	background: #075DCA url('./images/play.svg') 85% center no-repeat;
	border-radius: 12px;
	color: #fff;
	padding-left: 21px;
	align-items: center;
	margin: 5px;
	font-size: 14px;
	font-weight: bold;
}
.button-active {
	display: flex;
	height: 51px;
	width: 50%;
	background: #fff url('./images/play-active.svg') 85% center no-repeat;
	border-radius: 12px;
	color: #075DCA;
	padding-left: 21px;
	align-items: center;
	margin: 5px;
	font-size: 14px;
	font-weight: bold;
}
.to-wall-button,
.to-history-button,
.to-wall-done,
.to-history-done {
	display: flex;
	height: 40px;
	width: 50%;
	border-radius: 12px;
	padding-left: 21px;
	align-items: center;
	margin: 5px;
	font-size: 14px;
	margin-bottom: 10px;
}
.to-wall-button{
	background: #075DCA url('./images/toWall.svg') 85% center no-repeat;
	color: #fff;
}
.to-history-button{
	background: #075DCA url('./images/toHistory.svg') 85% center no-repeat;
	color: #fff;
}
.to-wall-done{
	background: #fff url('./images/toWallDone.svg') 85% center no-repeat;
	color: #075DCA;
}
.to-history-done{
	background: #fff url('./images/toHistoryDone.svg') 85% center no-repeat;
	color: #075DCA;
}
.player-border {
	border-radius: 12px;
}
.stream-frame {
	border: 0 none transparent;
}
.crew {
	margin-left: 10px;
	white-space: nowrap;
	display: flex;
	flex-wrap: nowrap;
	overflow-x: auto;
	align-content: flex-start;
	flex-shrink: 0;
	flex-grow: 0;
	overflow: -moz-scrollbars-none;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: none;
	padding-bottom: 50px;
}
.crew::-webkit-scrollbar { width: 0; }
.cosmonaut {
	width: 130px;
	height: 270px;
	margin-right: 10px;
	z-index: 0;
	flex: 0 0 128px;
}
.legend {
	width: 130px;
	height: 285px;
	margin-right: 10px;
	z-index: 0;
	flex: 0 0 128px;
}
.ads {
	background: url('./images/advertising.jpg')no-repeat center center;
	background-size: cover;
}
.photo {
	width: 130px;
	height: 180px;
	border-radius: 12px;
	margin-bottom: 13px;
}
.name {
	font-size: 13px;
	color: #fff;
	font-weight: bold;
	line-height: 16px;
	margin: 0;
	padding-top: 3px;
}
.job {
	padding-top: 8px;
	color: #728FBB;
	line-height: 16px;
	margin: 0;
	font-size: 12px !important;
}
.record {
	padding-top: 8px;
	color: #728FBB;
	line-height: 6px;
	margin: 0;
	font-size: 12px !important;
}
.biography-block {
	width: 100%;
	height: calc(100vh - 60px - constant(safe-area-inset-top));
	height: calc(100vh - 60px - env(safe-area-inset-top));
	overflow: -moz-scrollbars-none;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: none;
}
.close {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 60px;
	height: 60px;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 30px;
	font-weight: bold;
	background: url('./images/back.svg')no-repeat center center;
}
.about-cosmonaut {
	margin: 10px;
	height: calc(100vh - 80px - constant(safe-area-inset-top) - constant(safe-area-inset-bottom));
	height: calc(100vh - 80px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
	overflow-x: auto;
	color: #fff;
}
.about-cosmonaut span p {
	margin: 5px 0 0px 0;
    line-height: 18px;
    text-indent: 8px;
}
.bio-avatar {
	float: left;
	margin: 6px 10px 10px 0;
	width: 130px;
	height: 180px;
}
.bio-name {
	font-size: 20px;
	display: inline-block;
	margin: 3px 7px 0 0;
	font-weight: bold;
}
.bio-job {
	margin: 7px 0 5px;
	font-size: 18px;
	color: #728FBB;
	line-height: 20px;
}
.text-of-biography {
	margin: 0;
	line-height: 16px;
}
.disconnection {
	color: #fff;
	height: calc(100vh - 80px - constant(safe-area-inset-top) - constant(safe-area-inset-bottom));
	height: calc(100vh - 80px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 0 50px;
}
.Panel .Panel__in,
.Panel::after {
	background-color: rgba(0, 0, 0, 0) !important;
}
#isst { width: 100%; display: none; }
#isstwp { width: 100%; }